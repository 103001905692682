import React from 'react';
import chatBotIcon from './images/sofabuddy_chat_bubble.svg';

export const chatbotSettings = (
  t,
  closeChatIcon,
  sofabuddyCookieExists,
  sofabuddyThreadId,
  storageKey
) => {
  return {
    header: {
      showAvatar: false,
      title: t('sofabuddy_chat_bot.title'),
      closeChatIcon: closeChatIcon
    },
    chatButton: {
      icon: chatBotIcon
    },
    chatWindow: {
      showMessagePrompt: false
    },
    advance: {
      useAdvancedMessages: true,
      useAdvancedStyles: true
    },
    tooltip: {
      mode: sofabuddyCookieExists ? 'NEVER' : 'ALWAYS',
      text: (
        <span
          dangerouslySetInnerHTML={{ __html: t('sofabuddy_chat_bot.tooltip') }}
        />
      )
    },
    notification: {
      disabled: true
    },
    general: {
      embedded: false,
      flowStartTrigger: 'ON_CHATBOT_INTERACT',
      fontFamily: 'Open Sans, Helvetica, Arial, sans-serif'
    },
    chatHistory: {
      storageKey: storageKey,
      viewChatHistoryButtonText: (
        <>
          {t('sofabuddy_chat_bot.show_chat_history')}{' '}
          <i className="icon icon--replay"></i>
        </>
      ),
      autoLoad: sofabuddyThreadId ? true : false
    },
    botBubble: {
      dangerouslySetInnerHtml: true,
      simStream: window.Sofatutor.env == 'test' ? false : true
    },
    chatInput: {
      enabledPlaceholderText: t('sofabuddy_chat_bot.placeholder_text')
    },
    footer: {
      text: t('sofabuddy_chat_bot.mistake_hint')
    },
    fileAttachment: {
      disabled: true
    },
    emoji: {
      disabled: true
    }
  };
};
